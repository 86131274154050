/* eslint-disable jsx-a11y/alt-text */
import React, { FC } from 'react';
import Layout from '@/common/ui/template/Layout';

import Header from '@/newcar/ui/common/organism/Header';
import Footer from '@/newcar/ui/common/organism/Footer';
// import { Link } from 'gatsby';
// import NEWCAR_DEFINE from '@/newcar/util/define';

import listBtnToyota from './img/404/list-btn-toyota.png';
import listBtnNissan from './img/404/list-btn-nissan.png';
import listBtnHonda from './img/404/list-btn-honda.png';
import listBtnMazda from './img/404/list-btn-mazda.png';
import listBtnSubaru from './img/404/list-btn-subaru.png';
import listBtnSuzuki from './img/404/list-btn-suzuki.png';
import listBtnDaihatsu from './img/404/list-btn-daihatsu.png';
import listBtnMitsubishi from './img/404/list-btn-mitsubishi.png';
import listBtnMini from './img/404/list-btn-mini.svg';
import listBtnCompact from './img/404/list-btn-compact.svg';
import listBtnMinivan from './img/404/list-btn-minivan.svg';
import listBtnSuv from './img/404/list-btn-suv.svg';
import listBtnSedan from './img/404/list-btn-sedan.svg';
import listBtnHatchback from './img/404/list-btn-hatchback.svg';
import listBtnWagon from './img/404/list-btn-wagon.svg';
import listBtnCoupe from './img/404/list-btn-coupe.svg';
import imgConcierge from './img/404/img-concierge.svg';
import btnCall from './img/404/btn-call.svg';
import btnLine from './img/404/btn-line.svg';

const styles = require('./404.module.styl');

const page: FC = () => (
  <Layout>
    <Header />
    <main className="has-background-white">
      <h1 className={`${styles.firstView}`}>
        <p className="has-text-centered">
          <span className={`${styles.firstViewBalloon}`}>404 NOT FOUND</span>
          お探しのページは
          <br className="is-hidden-tablet" />
          見つかりませんでした。
        </p>
      </h1>
      <section className={`${styles.searchAreaWrap}`}>
        <p className="is-margin-top-7 is-margin-bottom-7">定額カルモくんのWebサイトにお越しいただきありがとうございます。お探しのページはすでに削除された可能性があります。</p>
        <h2 className={`is-margin-top-7 has-text-weight-bold has-text-centered ${styles.searchHeader}`}>お車を探したい方はこちらからお探しください</h2>
        <div className={`${styles.btnListWrap}`}>
          <div className={`${styles.btnList}`}>
            <p className={`is-margin-top-7 is-margin-bottom-3 has-text-weight-semibold has-text-centered ${styles.searchTitle}`}>
              <span className={`${styles.large}`}>メーカー</span>
              から探す
            </p>
            <ul className={`${styles.makerList}`}>
              <li className={`${styles.makerListItem}`}><a href="/lineup/toyota"><img src={listBtnToyota} alt="トヨタ" /></a></li>
              <li className={`${styles.makerListItem}`}><a href="/lineup/nissan"><img src={listBtnNissan} alt="日産" /></a></li>
              <li className={`${styles.makerListItem}`}><a href="/lineup/honda"><img src={listBtnHonda} alt="ホンダ" /></a></li>
              <li className={`${styles.makerListItem}`}><a href="/lineup/mazda"><img src={listBtnMazda} alt="マツダ" /></a></li>
              <li className={`${styles.makerListItem}`}><a href="/lineup/subaru"><img src={listBtnSubaru} alt="スバル" /></a></li>
              <li className={`${styles.makerListItem}`}><a href="/lineup/suzuki"><img src={listBtnSuzuki} alt="スズキ" /></a></li>
              <li className={`${styles.makerListItem}`}><a href="/lineup/daihatsu"><img src={listBtnDaihatsu} alt="ダイハツ" /></a></li>
              <li className={`${styles.makerListItem}`}><a href="/lineup/mitsubishi"><img src={listBtnMitsubishi} alt="三菱" /></a></li>
            </ul>
          </div>
          <div className={`${styles.btnList}`}>
            <p className={`is-margin-top-7 is-margin-bottom-3 has-text-weight-semibold has-text-centered ${styles.searchTitle}`}>
              <span className={`${styles.large}`}>車のタイプ</span>
              から探す
            </p>
            <ul className={`${styles.typeList}`}>
              <li className={`${styles.typeListItem}`}>
                <a href="/lineup/mini">
                  <img src={listBtnMini} alt="軽自動車" />
                  <span className="is-margin-top-3">軽自動車</span>
                </a>
              </li>
              <li className={`${styles.typeListItem}`}>
                <a href="/lineup/compact">
                  <img src={listBtnCompact} alt="コンパクトカー" />
                  <span className="is-margin-top-3">
                    コンパクト
                    <br />
                    カー
                  </span>
                </a>
              </li>
              <li className={`${styles.typeListItem}`}>
                <a href="/lineup/minivan">
                  <img src={listBtnMinivan} alt="ミニバン・ワゴン" />
                  <span className="is-margin-top-3">
                    ミニバン
                    <br />
                    ワゴン
                  </span>
                </a>
              </li>
              <li className={`${styles.typeListItem}`}>
                <a href="/lineup/suv">
                  <img src={listBtnSuv} alt="SUV・クロカン" />
                  <span className="is-margin-top-3">
                    SUV
                    <br />
                    クロカン
                  </span>
                </a>
              </li>
              <li className={`${styles.typeListItem}`}>
                <a href="/lineup/sedan">
                  <img src={listBtnSedan} alt="セダン" />
                  <span className="is-margin-top-4">セダン</span>
                </a>
              </li>
              <li className={`${styles.typeListItem}`}>
                <a href="/lineup/hatchback">
                  <img src={listBtnHatchback} alt="ハッチバック" />
                  <span className="is-margin-top-4">ハッチバック</span>
                </a>
              </li>
              <li className={`${styles.typeListItem}`}>
                <a href="/lineup/stationwagon">
                  <img src={listBtnWagon} alt="ステーションワゴン" />
                  <span className="is-margin-top-3">
                    ステーション
                    <br />
                    ワゴン
                  </span>
                </a>
              </li>
              <li className={`${styles.typeListItem}`}>
                <a href="/lineup/coupe">
                  <img src={listBtnCoupe} alt="クーペ・オープンカー" />
                  <span className="is-margin-top-3">
                    クーペ
                    <br />
                    オープンカー
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className={`${styles.carListBtnWrap}`}>
          <div className={`${styles.carList}`}>
            <p className={`is-margin-top-7 is-margin-bottom-3 has-text-weight-semibold has-text-centered ${styles.searchTitle}`}>
              <span className={`${styles.large}`}>新車の一覧</span>
              から探す
            </p>
            <a href="/lineup/" className={`has-text-centered has-text-weight-semibold  ${styles.normalLinkBtn}`}>新車の一覧</a>
          </div>
          <div className={`${styles.carList}`}>
            <p className={`is-margin-top-7 is-margin-bottom-3 has-text-weight-semibold has-text-centered ${styles.searchTitle}`}>
              <span className={`${styles.large}`}>中古車の一覧</span>
              から探す
            </p>
            <a href="/usedcar/lineup/" className={`has-text-centered has-text-weight-semibold  ${styles.normalLinkBtn}`}>中古車の一覧</a>
          </div>
        </div>
      </section>
      <section className={`${styles.linkAreaWrap}`}>
        <h2 className={`is-margin-top-7 has-text-weight-bold has-text-centered ${styles.searchHeader}`}>サービス内容について知りたい方はこちらから</h2>
        <div className={`is-margin-top-7 ${styles.linkListBtnWrap}`}>
          <div className={`${styles.carList}`}>
            <a href="/" className={`is-margin-top-7 has-text-centered has-text-weight-semibold  ${styles.normalLinkBtn}`}>TOPページ</a>
          </div>
          <div className={`is-margin-top-7 ${styles.carList}`}>
            <a href="/docs/faq/" className={`has-text-centered has-text-weight-semibold  ${styles.normalLinkBtn}`}>よくあるご質問</a>
          </div>
        </div>
        <h3 className={`is-margin-top-7 has-text-centered ${styles.conciergeHeader}`}>
          <img src={imgConcierge} alt="電話やLINEでご相談承っております" />
        </h3>
        <p className={`is-margin-top-7 is-size-4 ${styles.conciergeText}`}>
          おクルマ選びやサービスに関するご質問など電話やLINEでご相談を承っております。
          <br />
          専任のマイカーコンシェルジュがお客様のお悩み解決のお手伝いしますので、お気軽にお問い合わせください。
        </p>
        <ul className={`is-margin-top-7 ${styles.conciergeContact}`}>
          <li><a href="tel:0120-559-442"><img src={btnCall} alt="電話で無料相談" /></a></li>
          <li><a href="https://liff.line.me/1657071928-Br8GdoPL?sl=952e1e635d"><img src={btnLine} alt="LINEで無料相談" /></a></li>
        </ul>
        <p className="is-margin-top-4 is-size-6 has-text-centered">受付時間：10:00-19:00（土日祝日・年末年始を除く）</p>
      </section>
    </main>
    <Footer />
  </Layout>
);

export default page;
